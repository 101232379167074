<template>
  <canvas>
  </canvas>
</template>
<script>
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
export default {
  name: 'Chart',
  props: {
    chartConfig: {
      type: Object,
      plugins: [ChartDataLabels],
      validator: function (value) {
        const keys = Object.keys(value)
        if (!keys.includes('type')) {
          //console.error('[Chart.js] Object must has type (key)')
          return false
        }
        if (!keys.includes('data')) {
          //console.error('[Chart.js] Object must has data (key)')
          return false
        }
        return true
      }
    }
  },
  data () {
    return {
      chart: ''
    }
  },
  watch: {
    chartConfig() {
      this.chart.destroy()
      this.renderChart()
    }
  },
  methods: {
    renderChartChangeData() {
      this.chart.destroy()
      this.renderChart()
    },
    renderChart: function() {
      const chartCanvas = this.$el.getContext('2d')
      const chartConfig = this.chartConfig
      this.chart = new Chart(chartCanvas, chartConfig) // eslint-disable-line no-new
    }
  },
  mounted () {
    this.renderChart()
  }
}
</script>
<style lang="css">
</style>
