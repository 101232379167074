<template>
  <div class="container-fluid text-left" :style="'height: ' + screenHight + 'px'">
    <div class="row" :style="'height: ' + screenHight + 'px'">
      <div class="card-body pt-0 pb-0">
        <div class="row" :style="'height: ' + screenHight + 'px'">
          <div
            class="col-sm-6 p-0"
            :style="'border-right: 1px solid #e5e5e5; height: ' + screenHight + 'px'"
          >
            <!--  -->
            <div>
              <div class="opinion_title pl-2 pt-2 pr-2 pb-3">
                <strong style="color:#7D7D7D">{{ $t('opinion.content') }}</strong>
              </div>
              <div class="opinion_content" @click="onClickShowAddNewOpinion" v-if="isSecretaryRole()||isChairmanRole()">
                <div
                  class="p-2 pb-3 pt-3"
                  :style="showAddNewOpinion? 'background: #ECEFF0;' : 'border: 1px #ECEFF0 solid'"
                >
                  <img src="../../assets/icons/Plus.svg" />
                  <label class="pl-2 m-0">{{$t('opinion.createNewOpinion')}}</label>
                </div>
              </div>
            </div>
            <!--  -->
            <div :style="isSecretaryRole() || isChairmanRole()
                ? 'overflow-y: auto; height: ' + (screenHight-105) + 'px': 'overflow: auto; height: ' + (screenHight-46) + 'px'">
              <div
                class="opinion_content"
                v-for="(survey_info, index) in surveyData"
                :key="survey_info + index"
              >
                <div
                  class="p-3"
                  :style="(index==showQuestionContent && !showAddNewOpinion) ? 'background: #ECEFF0; display: flex' : 'display: flex; border: 1px #ECEFF0 solid' "
                  @click="showAnswerSurvey(survey_info, index)"
                >
                  <p class="col-11 pl-0 mb-0">{{ survey_info.question }}</p>
                  <div class="col-1 float-right" style="align-self: center;">
                    <img v-if="index==showQuestionContent" src="../../assets/icons/chevron-down.svg" />
                    <img v-else src="../../assets/icons/chevron-up.svg" />
                  </div>
                </div>
                <div class="question_content pl-4 pr-4" v-show="index==showQuestionContent">
                  <table class="table table-borderless" id="table_question_content">
                    <tbody>
                      <tr>
                        <td>{{$t('opinion.security')}}</td>
                        <td
                          style="color: #FF4081"
                        >{{ survey_info.security == true ? $t('opinion.haveSecurity') : $t('opinion.noSecurity') }}</td>
                      </tr>
                      <tr>
                        <td>{{$t('opinion.startTime')}}</td>
                        <td>{{ survey_info.start_time }}</td>
                      </tr>
                      <tr>
                        <td>{{$t('opinion.endTime')}}</td>
                        <td>{{ survey_info.end_time }}</td>
                      </tr>
                      <tr>
                        <td>{{$t('opinion.followers')}}</td>
                        <td>{{ survey_info.user_create}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('opinion.opinionCollector')}}</td>
                        <td
                          style="display: flex; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                        >
                          <div
                            v-for="(user_answer_info, index) in survey_info.user_answer"
                            :key="user_answer_info + index"
                          >
                            <div class="pr-1" v-if="index < 5">
                              <avatar
                                v-if="user_answer_info.url_avatar"
                                :src="user_answer_info.url_avatar"
                                backgroundColor="#e5e5e5"
                                color="#0097A7"
                                :size="30"
                              ></avatar>
                              <avatar
                                v-else
                                :username="user_answer_info.full_name"
                                backgroundColor="#e5e5e5"
                                color="#0097A7"
                                :size="30"
                              ></avatar>
                            </div>
                          </div>
                          <span v-if="survey_info.user_answer.length>5" style="font-size: 16px">...</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <button
                            type="button"
                            class="btn mr-3"
                            style="color:#26BA50; border: 1px solid #26BA50; font-size: 14px !important; padding: 2px 15px"
                            @click="showModalUserAnswer(survey_info)"
                          >{{$t('opinion.viewFull')}}</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
          <div class="col-sm-6 p-0">
            <div class="plan_title pl-2 pt-2 pr-2 pb-3">
              <strong style="color: #7D7D7D">{{ $t('opinion.plan')}}</strong>
            </div>
            <!--  -->
            <add-new-opinion v-if="showAddNewOpinion"></add-new-opinion>
            <!--  -->
            <!-- Answer survey -->
            <div
              v-if="!showAddNewOpinion && !checkShowChart && answerSurvey.length > 0"
              class="p-4"
              style="background: #ECEFF0"
            >
              <div
                v-if="GET_ANSWER_SURVEY_DATA.person_answer || GET_ANSWER_SURVEY_DATA.person_other_answer"
                class="pb-3"
                style="color: #3F51B5; font-weight: bold"
              >{{$t('opinion.notificationAnswer')}}</div>
              <div v-for="(answer, index) in answerSurvey" :key="answer + index">
                <div class="custom-control custom-radio pb-3">
                  <input
                    type="radio"
                    class="custom-control-input"
                    :id="answer.value"
                    name="answer.text"
                    :value="answer.value"
                    v-model="answerSelect"
                    :disabled="disabledAnswer"
                    v-on:click="otherAnswer=''"
                  />
                  <label
                    class="custom-control-label"
                    :for="answer.value"
                    :style="(GET_ANSWER_SURVEY_DATA.security == true & answer.value==answerSelect) ? 'font-weight: bold;':''"
                  >{{answer.text}}</label>
                </div>
              </div>
              <div class="text-center pl-4 pr-4">
                <textarea
                  class="form-control p-2"
                  cols="35"
                  rows="5"
                  placeholder="Nhập ý kiến khác"
                  v-model="otherAnswer"
                  :disabled="disabledAnswer"
                  v-on:click="answerSelect=''"
                ></textarea>
              </div>
              <div class="text-center pt-4">
                <button
                  type="button"
                  class="btn send-btn text-white"
                  @click="saveOpinion"
                  :disabled="disabledAnswer"
                >{{$t('opinion.submitComments')}}</button>
              </div>
            </div>
            <!-- end Answer survey -->
            <!-- chart -->
            <div v-if="!showAddNewOpinion && checkShowChart" class style="background: #ECEFF0">
              <div class="pt-3 pr-4 pb-3 pl-4" style="color: #FF6E6E">
                <strong>{{ $t('opinion.statistical') }}</strong>
              </div>
              <opinionChart
                style="height:250px"
                :chartConfig="answerSurveyChartConfig"
                ref="getRenderChart"
              ></opinionChart>
              <!-- other opinion -->
              <div class="row pb-4 pt-4 pl-4 m-2" 
                style="font-size: 15px; display: flex" 
                v-if="GET_ANSWER_SURVEY_DATA.other_answer != undefined"
              >
                <div
                  v-if="this.GET_ANSWER_SURVEY_DATA.other_answer.length !=0"
                  class="col-4 p-0"
                  style="display: inline;"
                >{{ $t('opinion.anotherOpinionContent') }}:</div>
                <div class="col-8" style>
                  <p
                    v-for="(other_opinion, index) in this.GET_ANSWER_SURVEY_DATA.other_answer"
                    :key="other_opinion + index"
                  >{{ other_opinion }}</p>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <b-modal
      v-model="isShow"
      centered
      modal-class="modal-show-user_answer"
      body-bg-variant="red"
      id="modal-show-user_answer"
      size="sd"
    >
      <template v-slot:modal-header>
        <div class="w-100 pl-2 pr-2">{{$t('opinion.opinionCollectors')}}</div>
      </template>
      <div class="container pl-4 pr-4">
        <div class="text-justify row pb-3">{{dataInModal.question}}</div>
        <div class="row overflow-auto" style="height: 350px; background: #fff">
          <div
            class="col-12 pt-1 pb-1 p-0"
            v-for="(user_answer_data, index) in dataInModal.user_answer"
            :key="user_answer_data+index"
            style="display: flex; font-size: 14px"
          >
            <div class="pl-3 pr-3" style="color: #707070">{{index + 1}}</div>
            <div>
              <p class="m-0">{{user_answer_data.full_name}}</p>
              <p class="m-0" style="color: #707070">
                {{getCompanyAndPosition(user_answer_data.company_name,user_answer_data.company_position)}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 pl-4 pr-4">
          <b-button
            variant="primary"
            size="sm"
            class="float-left pl-4 pr-4 btn-close"
            @click="$bvModal.hide('modal-show-user_answer')"
          >{{ $t('common.close') }}</b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dateUtils from "utils/dateUtils";
import OpinionChart from "Components/OpinionChart";
import Avatar from "vue-avatar";
import AddNewOpinion from "../Opinion/AddNewOpinion";
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import Enum from 'enum/enum';

// import surveyType from "enum/survey"
export default {
  components: {
    opinionChart: OpinionChart,
    avatar: Avatar,
    "add-new-opinion": AddNewOpinion
  },
  data: () => ({
    //
    // survey
    surveyData: [],
    answerSurvey: [],
    answerSelect: "",
    showQuestionContent: 0,
    otherAnswer: "",
    checkShowChart: false,
    // chart
    answerSurveyChartConfig: {
      type: "pie",
      data: {
        labels: [],
        datasets: [
          {
            label: "# of Votes",
            data: [],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 206, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(255, 159, 64)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          //
          datalabels: {
            color: "#fff",
            font: {
              weight: "bold"
            },
            formatter: value => {
              if (value == 0) return null;
              return value + " %";
            }
          }
        }
      }
    },
    isShow: false,
    dataInModal: [],
    showAddNewOpinion: false,
    screenHight: 0,
    disabledAnswer: true,
    event_id: null,
    opinionSelectedIndex: 0
  }),
  watch: {
    GET_SURVEYQUESTION_DATA() {
      this.surveyData = [];
      this.GET_SURVEYQUESTION_DATA.forEach(data => {
        let surveyObj = {
          id: data.id,
          question: data.question_details,
          start_time: this.formatDateSurvey(data.start_datetime),
          end_time: this.formatDateSurvey(data.end_datetime),
          type_question: data.type_of_question_code.id,
          user_create: data.created_by,
          answer_info: this.editDataAnswerSurvey(data.answer_info),
          user_answer: data.user_answer,
          security: data.security
        };
        this.surveyData.push(surveyObj);
      });
      this.answerSurvey =
        this.surveyData[this.opinionSelectedIndex] != undefined ? this.surveyData[this.opinionSelectedIndex].answer_info : [];
      if (this.answerSurvey.length > 0)
        this.getAnswerSurvey(this.surveyData[this.opinionSelectedIndex].id);
    },
    GET_ANSWER_SURVEY_DATA() {
      let start_time = moment(this.GET_ANSWER_SURVEY_DATA.start_datetime).format('YYYY/MM/DD');
      let end_time = moment(this.GET_ANSWER_SURVEY_DATA.end_datetime).format('YYYY/MM/DD');
      let now_time = moment(moment()).format('YYYY/MM/DD');
      let answerTextData = this.GET_ANSWER_SURVEY_DATA.answer_text
      if (answerTextData !== undefined) {
        let otherIndex = answerTextData.findIndex(x=>x==='other')
        if (otherIndex !== -1) {
          answerTextData[otherIndex] = this.$t('opinion.another_opinion')
        }
      }
      if (this.isChairmanRole()){
        if(now_time < start_time){
          this.disabledAnswer = true;
          this.checkShowChart = false;
        }
        else if(now_time > end_time) {
          this.checkShowChart=true;
          if (answerTextData != undefined) {
            this.answerSurveyChartConfig.data.labels = answerTextData;
            this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
            this.renderChartData();
          }else{
            this.answerSurveyChartConfig.data.labels = [this.$t('opinion.chartEmptyData[0].labels')];
            this.answerSurveyChartConfig.data.datasets[0].data = [0];
            this.renderChartData();
          }
        }else{
          // if (this.GET_ANSWER_SURVEY_DATA.answer_text != undefined) {
          //   // survey assign for chairman
          //   if (this.GET_ANSWER_SURVEY_DATA.current_user_answer != undefined){
          //     // survey answer not answer
          //     if(this.GET_ANSWER_SURVEY_DATA.current_user_answer == false){
          //       this.checkShowChart=false;
          //       this.disabledAnswer=false;
          //     }
          //     // The survey has been answered
          //     else{
          //       this.checkShowChart=true;
          //       this.answerSurveyChartConfig.data.labels = this.GET_ANSWER_SURVEY_DATA.answer_text;
          //       this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
          //       this.renderChartData();
          //     }
          //   }
          //   // survey not assign for chairman
          //   else{

          //   }
            
          // }else{
          //   this.checkShowChart=false;
          //   this.disabledAnswer=false;
          // }
          if (this.GET_ANSWER_SURVEY_DATA.current_user_answer != undefined){
            if(this.GET_ANSWER_SURVEY_DATA.current_user_answer == true){
              this.checkShowChart=true;
              this.answerSurveyChartConfig.data.labels = answerTextData;
              this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
              this.renderChartData();
            }else{
              this.checkShowChart=false;
              this.disabledAnswer=false;
            }
          }else{
            if (answerTextData != undefined){
              this.checkShowChart=true;
              this.answerSurveyChartConfig.data.labels = answerTextData;
              this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
              this.renderChartData();
            }else{
              this.answerSurveyChartConfig.data.labels = [this.$t('opinion.chartEmptyData[0].labels')];
              this.answerSurveyChartConfig.data.datasets[0].data = [0];
              this.renderChartData();
            }
          }
        }
      }
      else{
        if(now_time < start_time){
          this.disabledAnswer = true;
          this.checkShowChart = false;
        }
        else if(now_time > end_time){
          this.disabledAnswer = true;
          if (answerTextData != undefined) {
            this.checkShowChart = true;
            this.answerSurveyChartConfig.data.labels = answerTextData;
            this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
            this.renderChartData();
          } else if (this.GET_ANSWER_SURVEY_DATA.person_answer != undefined) {
            this.checkShowChart = false;
            this.disabledAnswer = true;
            this.answerSelect = this.GET_ANSWER_SURVEY_DATA.person_answer;
            this.otherAnswer = "";
          }else if (this.GET_ANSWER_SURVEY_DATA.person_other_answer != undefined) {
            this.checkShowChart = false;
            this.disabledAnswer = true;
            this.otherAnswer = this.GET_ANSWER_SURVEY_DATA.person_other_answer;
          }
          else{
            this.checkShowChart = false;
            this.otherAnswer = "";
          }
        }
        else{
          this.disabledAnswer = false;
          // survey security
          if (this.GET_ANSWER_SURVEY_DATA.security){
            if (this.GET_ANSWER_SURVEY_DATA.person_answer != undefined) {
              this.checkShowChart = false;
              this.disabledAnswer = true;
              this.answerSelect = this.GET_ANSWER_SURVEY_DATA.person_answer;
              this.otherAnswer = "";
            }else if (this.GET_ANSWER_SURVEY_DATA.person_other_answer != undefined) {
              this.checkShowChart = false;
              this.disabledAnswer = true;
              this.otherAnswer = this.GET_ANSWER_SURVEY_DATA.person_other_answer;
            }else{
              this.checkShowChart = false;
              this.disabledAnswer = false;
              this.otherAnswer = "";
              this.answerSelect = "";
            }
          }
          // survey not security
          else{
            // survey assign user
            if (this.GET_ANSWER_SURVEY_DATA.assign_current_user != undefined){
              // assign current user
              if (this.GET_ANSWER_SURVEY_DATA.assign_current_user){
                // answered
                if (answerTextData != undefined){
                  this.checkShowChart=true;
                  this.answerSurveyChartConfig.data.labels = answerTextData;
                  this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
                  this.renderChartData();
                }else{
                  this.checkShowChart = false;
                  this.disabledAnswer = false;
                  this.otherAnswer = "";
                }
              }
              // 
              else{
                if (answerTextData != undefined){
                  this.checkShowChart=true;
                  this.answerSurveyChartConfig.data.labels = answerTextData;
                  this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
                  this.renderChartData();
                }else{
                  this.answerSurveyChartConfig.data.labels = [this.$t('opinion.chartEmptyData[0].labels')];
                  this.answerSurveyChartConfig.data.datasets[0].data = [0];
                  this.renderChartData();
                }
              }
            }else{
              if (answerTextData != undefined){
                this.checkShowChart=true;
                this.answerSurveyChartConfig.data.labels = answerTextData;
                this.answerSurveyChartConfig.data.datasets[0].data = this.GET_ANSWER_SURVEY_DATA.answer;
                this.renderChartData();
              }else{
                this.checkShowChart = false;
                this.disabledAnswer = false;
                this.otherAnswer = "";
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "GET_SURVEYQUESTION_DATA",
      "GET_CURRENT_ACCOUNT",
      "GET_SNACK_BAR",
      "GET_ANSWER_SURVEY_DATA"
    ])
  },
  methods: {
    ...mapActions([
      "GET_SURVEYQUESTION_LIST_FOR_MEETING",
      "ADD_ANSWER_SURVEYQUESTION",
      "SET_USER_DATA",
      "ON_SHOW_TOAST",
      "GET_ANSWER_SURVEY"
    ]),
    getCompanyAndPosition: function(company, position){
      return functionUtils.getCompanyAndPosition(company, position)
    },
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
    },
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    formatDateSurvey(dateTimeData) {
      let timeSurvey = dateUtils.formatDate(
        dateTimeData,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "HH:mm"
      );
      if (timeSurvey == "00:00") {
        return dateUtils.formatDate(
          dateTimeData,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
          "DD/MM/YYYY"
        );
      } else {
        return dateUtils.formatDate(
          dateTimeData,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
          "HH:mm DD/MM/YYYY"
        );
      }
    },
    editDataAnswerSurvey(answerData) {
      let answerItem = [];
      answerData.forEach(data => {
        let answer = {
          value: data.id,
          text: data.answer_option
        };
        answerItem.push(answer);
      });
      return answerItem;
    },
    showAnswerSurvey(survey, index) {
      this.answerSurvey = survey.answer_info;
      this.showQuestionContent = index;
      this.getAnswerSurvey(survey.id);
      this.showAddNewOpinion = false;
    },
    saveOpinion() {
      if ((this.answerSelect == "" || this.answerSelect == null) && (this.otherAnswer == "" || this.otherAnswer == null)) {
        this.ON_SHOW_TOAST({
          message: this.$t('opinion.errorNotAnswer'),
          styleType: "danger"
        });
      } else {
        let data = {
          survey_question_id: this.surveyData[this.showQuestionContent].id,
          answer: this.answerSelect != "" ? this.answerSelect : null,
          // user_id: this.GET_CURRENT_ACCOUNT.id,
          other_answer: this.otherAnswer != "" ? this.otherAnswer : null
        };
        this.ADD_ANSWER_SURVEYQUESTION(data)
          .then(
            function() {
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
              this.getAnswerSurvey(
                this.surveyData[this.showQuestionContent].id
              );
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    getAnswerSurvey(survey_id) {
      const get_answer = {
        params: {
          survey_question_id: survey_id
        }
      };
      this.GET_ANSWER_SURVEY(get_answer);
    },
    renderChartData() {
      if (this.$refs.getRenderChart != undefined) {
        this.$refs.getRenderChart.renderChartChangeData();
      }
    },
    showModalUserAnswer(surveyQuestionData) {
      this.isShow = true;
      this.dataInModal = surveyQuestionData;
    },
    onClickShowAddNewOpinion() {
      this.showAddNewOpinion = true;
    },
    handleResize() {
      this.screenHight = window.innerHeight - 52;
    },
    // socket
    webSocketOpinion: function(){
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws"
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL)
      const socket = new WebSocket(
        ws_scheme +
        '://'
        + backEndUrl.host
        + `/${Enum.ChannelsActionCodeEnum.Opinion}/`
      )
      socket.onmessage = function (event)  {
        if (event.data != undefined){
          let message_data = JSON.parse(event.data)
          if (message_data.message == Enum.ActionEnum.Opinion){
            const event = {
              params: {
                event: this.event_id,
                type_survey: 13
              }
            };
            this.GET_SURVEYQUESTION_LIST_FOR_MEETING(event);
            this.findOpinionSelected()
          }
        }
      }.bind(this)

      socket.onclose = function() {
        // this.connected = false
      }
    },
    findOpinionSelected: function(){
      let index = this.surveyData.findIndex(x=>x.id == this.opinionSelectedIndex)
      if (index != -1){
        this.getAnswerSurvey(this.surveyData[this.opinionSelectedIndex].id);
      }
    }
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id
    const event = {
      params: {
        event: event_id,
        type_survey: 13
      }
    };
    this.GET_SURVEYQUESTION_LIST_FOR_MEETING(event);
    let filter = {
      params: {
        event: event_id ? event_id : null
      }
    };
    this.SET_USER_DATA(filter);
    this.webSocketOpinion()
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/color.scss";
.bv-no-focus-ring /deep/ div {
  padding: 10px;
}
.send-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
table#table_question_content td:nth-child(1) {
  color: #707070;
  font-size: 14px;
  width: 25% !important;
  white-space: nowrap !important;
}
table#table_question_content td:nth-child(2) {
  font-size: 14px;
}
table .absorbing-column {
  width: 100%;
}
.btn-close {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
/deep/ .modal-show-user_answer .modal-content {
  background: #eceff0;
}
/deep/ .modal-header {
  border: none;
}
/deep/ .modal-body {
  padding-top: 0;
}
/deep/ .modal-footer {
  border: none;
}
</style>