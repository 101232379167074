<template>
  <div
    class="add-new-opinion"
    :style="'background: #ECEFF0; font-size: 15px; height: ' + screenHight"
  >
    <div class="col-12">
      <div class="form-group">
        <label>{{$t('opinion.addOpinionContent')}}</label>
        <textarea
          class="form-control"
          rows="3"
          v-model="opinionData.opinionContent"
          :placeholder="$t('opinion.opinionContent')"
        ></textarea>
      </div>
    </div>
    <div class="col-12 pb-3" style="display: flex">
      <label for class="mr-4">{{$t('opinion.security')}}</label>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customCheck"
          name="example1"
          v-model="opinionData.opinionSecurity"
        />
        <label class="custom-control-label" for="customCheck">{{$t('opinion.yes/no')}}</label>
      </div>
    </div>
    <div class="col-sm-12 pb-3">
      <div class="row">
        <div class="col-sm-6">
          <label>{{$t('opinion.startTime')}}</label>
          <date-picker
            style="font-size: 14px; text-align: right;"
            v-model="opinionData.startTime"
            :config="options"
          ></date-picker>
        </div>
        <div class="col-sm-6">
          <label>{{$t('opinion.endTime')}}</label>
          <date-picker
            style="font-size: 14px; text-align: right;"
            v-model="opinionData.endTime"
            :config="options"
          ></date-picker>
        </div>
      </div>
    </div>
    <div class="col-12 pb-3">
      <label>{{$t('mission.assignPersonCompany')}}</label>
      <VueMutiselect
        :optionData="optionData"
        :customLabel="customLabel"
        :labelText="labelTextValue"
        :trackByText="trackByTextValue"
        :placeHolder="$t('mission.selectPerson')"
        @select="getAssignerSelect"
        ref="VueMutiselect"
      ></VueMutiselect>
    </div>
    <!-- <div v-for="(planItem, index) in planList" :key="planItem+index"> -->
    <div class="col-11" v-for="(planItem, index) in planList" :key="planItem+index">
      <div class="form-group required">
        <label class="control-label">{{$t('opinion.plan')}} {{index + 1}}</label>
        <input type="text" class="form-control" v-model="planItem.answer_option" />
        <span type="button" class="btn btn-delete-plan" @click="removePlan(index)">
          <i class="fas fa-times" style="color: red"></i></span>
      </div>
    </div>
    <!-- </div> -->
    <div class="col-12">
      <div
        class="p-2 col-6"
        style="background: #FFFFFF; border-radius: .25rem; border: 1px solid #ced4da; display: flex"
        @click="onClickAddPlan"
      >
        <img src="../../assets/icons/Plus.svg" />
        <label class="m-0 pl-2">{{$t('opinion.addPlan')}}</label>
      </div>
    </div>
    <div class="col-12 pt-4 pb-4" style="justify-content: center; display: flex;">
      <button
        type="button"
        class="btn send-btn text-white pl-4 pr-4"
        @click="onSaveOpinion"
      >{{$t('opinion.createOpinion')}}</button>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import VueMutiselect from "Components/VueMutiselect";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import functionUtils from 'utils/functionUtils'
import Enum from "enum/enum"
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'

export default {
  components: {
    VueMutiselect
  },
  data: () => ({
    screenHight: 0,
    planList: [
      {
        id: 0,
        answer_option: ""
      }
    ],
    optionData: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    opinionData: {
      id: 0,
      assignerList: [],
      opinionContent: "",
      opinionSecurity: false,
      startTime: "",
      endTime: ""
    },
    options: {
      format: "DD/MM/YYYY",
      useCurrent: false,
      locale: "vi",
      minDate: false,
      maxDate: false
    },
    event_id: null
  }),
  watch: {
    GET_USER_IN_EVENT_LIST_DATA() {
      this.optionData = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let item = {
          id: data.id,
          full_name: full_name,
          url_avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group
        };
        this.optionData.push(item);
      });
    },
    EVENT_DETAIL_DATA(){
      this.options.minDate = moment(this.EVENT_DETAIL_DATA.start_datetime).format("DD/MM/YYYY")
    }
  },
  computed: {
    ...mapGetters(["GET_USER_IN_EVENT_LIST_DATA", "GET_SNACK_BAR", "EVENT_DETAIL_DATA", "GET_CURRENT_ACCOUNT"])
  },
  methods: {
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssignerSelect(data) {
      this.opinionData.assignerList = [];
      data.forEach(item => {
        this.opinionData.assignerList.push({id: item.id, is_group: item.is_group});
      });
    },
    onClickAddPlan() {
      let item = {
        id: 0,
        answer_option: ""
      };
      this.planList.push(item);
    },
    handleResize() {
      this.screenHight = window.innerHeight - 52;
    },
    onSaveOpinion() {
      let result = this.validateOpinion();
      if (result == "OK") {
        let data = {
          id: this.opinionData.id,
          event: this.event_id,
          question_content: this.opinionData.opinionContent,
          startTime: moment(this.opinionData.startTime, "DD/MM/YYYY")
            .utc()
            .format(),
          endTime: moment(this.opinionData.endTime, "DD/MM/YYYY")
            .utc()
            .format(),
          security: this.opinionData.opinionSecurity,
          assigner_list: this.opinionData.assignerList,
          plan_list: this.planList
        };
        this.ADD_SURVEYQUESTION(data)
          .then(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success"
              });
              this.sendNotification()
              // clear data
              this.$refs.VueMutiselect.clearAll();
              this.opinionData = {
                id: 0,
                assignerList: [],
                opinionContent: "",
                opinionSecurity: false,
                startTime: "",
                endTime: ""
              };
              this.planList = [
                {
                  id: 0,
                  answer_option: ""
                }
              ];
              // call back survey list
              const event = {
                params: {
                  event: this.event_id,
                  type_survey: 13
                }
              };
              this.GET_SURVEYQUESTION_LIST_FOR_MEETING(event);

            }.bind(this)
          )
          .catch(
            function(error) {
              let code = error.response.data.code;
              let mess = this.$t("common.somethingWentWrong")
              if (code == 'group_session_not_found') mess = this.$t('opinion.venueNotFound')
              this.ON_SHOW_TOAST({
                message: mess,
                styleType: "danger"
              });
            }.bind(this)
          );
      } else {
        this.ON_SHOW_TOAST({
          message: result,
          styleType: "danger"
        });
      }
    },
    validateOpinion() {
      let validatedResult = "OK";
      if (
        this.opinionData.opinionContent == null ||
        this.opinionData.opinionContent == ""
      ) {
        validatedResult = this.$t("opinion.opinionContent");
      } else if (
        this.opinionData.startTime == null ||
        this.opinionData.startTime == ""
      ) {
        validatedResult = this.$t("opinion.startTime");
      } else if (
        this.opinionData.endTime == null ||
        this.opinionData.endTime == ""
      ) {
        validatedResult = this.$t("opinion.endTime");
      } else {
        for (let i = 0; i < this.planList.length; i++) {
          if (
            this.planList[i] &&
            (this.planList[i].answer_option == null ||
              this.planList[i].answer_option == "")
          ) {
            validatedResult = this.$t("opinion.plan") + " " + (i + 1);
            break;
          }
        }
      }
      return validatedResult;
    },
    removePlan(index){
      if (this.planList.length > 1){
        this.planList.splice(index,1)
      }
    },
    sendNotification(){
      let userList = []
      let groupList = []

      this.opinionData.assignerList.forEach(data=>{
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let notificationData = {
        event_id: this.event_id,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.OPINION,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.Opinion,
        opinion_content: this.opinionData.opinionContent
      }
      if(this.opinionData.assignerList.length > 0){
        this.SEND_NOTIFICATION_IN_MEETING(notificationData)
      }

      let smsData = {
        event_id: this.event_id,
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.OPINION,
        template_type: Enum.TemplateType.SMS,
        opinion_content: this.opinionData.opinionContent
      }
      if(this.opinionData.assignerList.length > 0){
        this.SEND_SMS_IN_MEETING(smsData)
      }
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_USER_IN_EVENT_LIST",
      "ADD_SURVEYQUESTION",
      "GET_SURVEYQUESTION_LIST_FOR_MEETING",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING"
    ])
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id,
        bandGroup: true
      }
    };
    this.GET_USER_IN_EVENT_LIST(filter);
    let event = {
      params:{
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event)
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.add-new-opinion {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.form-group.required .control-label:after {
  color: #d00;
  position: absolute;
  margin-left: 4px;
  margin-top: -3px;
  font-family: "FontAwesome";
  font-weight: normal;
  font-size: 16px;
  content: "*";
  top: 0px;
}
.send-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.btn-delete-plan{
  position: absolute;
  top: 30px;
  float: right;
  right: -20px;
}
</style>